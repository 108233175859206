.wrapper {
  height: 80vh;

  .title {
    display: flex;
    align-items: center;

    & button {
      margin-left: 3rem;
    }
  }

  .table_wrapper {
    height: 70vh;
  }
}