.links_wrapper {
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 1;

  .links {
    background-color: #f3f3f3;
    border-radius: 1rem;
    padding: .1rem .5rem .23rem;
    width: max-content;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    & a {
      margin: .5rem;
      padding: .2rem;
      text-decoration: none;
      color: #1976d2;
      text-transform: uppercase;
      font-weight: 500;

      &:hover {
        background-color: rgba(25, 118, 210, 0.04);
        border-radius: .2rem;
      }
    }

    .active {
      border-bottom: #1976d2 solid .2rem;
    }
  }
}

@media (max-width: 480px) {
  .links_wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .links {
      background-color: #f3f3f3;
      border-radius: 1rem;
      width: max-content;
      display: flex;
      justify-content: center;

      & a {
        text-decoration: none;
        color: #1976d2;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 0.8rem;

        &:hover {
          background-color: rgba(25, 118, 210, 0.04);
          border-radius: .2rem;
        }
      }

      .active {
        border-bottom: #1976d2 solid .2rem;
      }
    }
  }
}