.container {
  display: flex;
  justify-content: center;
  flex-direction: column;

  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

  & > * {
    margin: 0.5rem;
  }

  .data_card {

    & li {
      padding: 1rem;
    }

    & p {
      padding-left: 1rem;
    }
  }
}

.card_form {
  width: 100%;
}

.form__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.duplicates_form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .duplicates_form__select_project {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

}

.transform__icon {
  transform: rotate(180deg);
}

.no__geometry__data {
  max-width: 40vw;
  display: flex;
  flex-direction: column;

  & > * {
    padding: .7rem;
  }
}

.info__button {
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info__button:hover {
  cursor: pointer;
  background: #e7e7e7;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}