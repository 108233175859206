.small_width_col {
  width: 5rem;
  background: white;
}

.medium_width_col {
  width: 10rem;
  background: white;
}

.large_width_col {
  width: 15rem;
  background: white;
}

.icon {
  font-size: 2rem;

  &:hover {
    color: #000;
    cursor: pointer;
  }
}

.header_name {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2rem;

  .icon_sorted_asc {
    transform: rotate(90deg);
    cursor: pointer;
    opacity: 0;
  }

  .icon_sorted_desc {
    transform: rotate(270deg);
    cursor: pointer;
    opacity: 0;
  }

  .icon_sorted_active {
    opacity: 1;
  }
}

.header_name:hover {
  .icon_sorted_asc {
    opacity: 1;
  }

  .icon_sorted_desc {
    opacity: 1;
  }
}

.search_icon_group__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .search_icon_group__icon {
    width: 3rem;
    height: 3rem;
    border-radius: 5px;
  }

  .search_icon_group__icon:hover {
    background-color: rgba(20, 128, 236, 0.1);
    cursor: pointer;
  }
}

.table_settings_dialog__wrapper {
  display: flex;
  justify-content: space-between;
  min-width: 40vw;
  height: 80vh;

  .table_settings_dialog__column {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin: 0 1rem;
    height: 75vh;
    overflow: auto;
  }

  .table_settings_dialog__column_label {
    font-size: 1.3rem;
    color: #0b2c54;
    margin: 0.5rem;
    padding: 0 1rem 1rem;
    border-bottom: #1480ec 1px solid;
    border-radius: 5px;
    max-width: 20vw;
  }

  .table_settings_dialog__item {
    background: none;
    font-size: 1.3rem;
    border: 0;
    box-sizing: border-box;
    padding: 1rem 1rem;
    color: #0b2c54;

    // Required, since we're setting absolute on pseudo-elements
    position: relative;
    vertical-align: middle;

    &::before,
    &::after {
      box-sizing: inherit;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  .table_settings_dialog__item_animation {
    transition: color 0.25s;

    &::before,
    &::after {
      // Set border to invisible, so we don't see a 4px border on a 0x0 element before the transition starts
      border: 1px solid transparent;
      width: 0;
      height: 0;
    }

    // This covers the top & right borders (expands right, then down)
    &::before {
      top: 0;
      left: 0;
    }

    // And this the bottom & left borders (expands left, then up)
    &::after {
      bottom: 0;
      right: 0;
    }

    &:hover {
      color: #1480ec;
      cursor: pointer;
    }

    // Hover styles
    &:hover::before,
    &:hover::after {
      width: 100%;
      height: 100%;
    }

    &:hover::before {
      border-top-color:  #1480ec; // Make borders visible
      border-right-color:  #1480ec;
      border-radius: 5px;
      transition:
              width 0.1s ease-out, // Width expands first
              height 0.1s ease-out 0.1s; // And then height
    }

    &:hover::after {
      border-bottom-color:  #1480ec; // Make borders visible
      border-left-color:  #1480ec;
      border-radius: 5px;
      transition:
              border-color 0s ease-out 0.2s, // Wait for ::before to finish before showing border
              width 0.1s ease-out 0.2s, // And then exanding width
              height 0.1s ease-out 0.3s; // And finally height
    }
  }
}