.projects_without_data {

  &:hover {
    cursor: pointer;
    color: #1480ec;
    text-decoration: underline;
  }
}

.project_info {

  & h6 {
    width: 17rem;
  }
}


.wrapper {
  display: flex;
  justify-content: center;

  & > * {
    margin: 1rem;
  }
}