.wrapper {
  padding: 0 2rem;
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.project__form {
  display: flex;
  flex-direction: column;
}

.select {
  min-width: 13rem;
}

.btn__submit {
  margin-top: .5rem;
}