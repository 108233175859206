
.card {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.wrapper {
  width: 40rem;
}

.wrapper_select_project {
  display: flex;
  justify-content: space-between;

  .source_project {
    margin-left: 2rem;

    & p {
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .recipient_project {
    margin-right: 2rem;
    margin-left: 2rem;

    & p {
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

  }
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}






