.wrapper {
  width: 100%;
  height: 88vh;
}

.form {
  display: flex;
  flex-direction: column;

  .input {
    margin-top: 1rem;
  }

  & button {
    margin-top: 1rem;
  }
}

.preview {
  width: 6rem;
  margin: .5rem;
}

.active_preview {
  border: 2px solid red;
}

.main_photo {
  max-height: 76vh;
}

.footer {
  z-index: 100;
  bottom: 0;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid gray;

  & button {
    margin: 1rem 5rem;
  }

  .go_to {
    margin: 1rem;
  }
}