.my_speed_dial__wrapper {
  position: absolute;
  min-width: 10rem;
  min-height: 5rem;
  bottom: 5rem;
  right: 5rem;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  z-index: 999;
}

.my_speed_dial__close_content_wrapper {
  position: absolute;
  min-width: 3rem;
  min-height: 5rem;
  bottom: 1rem;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
}

.my_speed_dial__open_content_wrapper {
  -webkit-animation: scale-up-ver-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-ver-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

  background-color: rgba(20, 128, 236, 0.29);
  color: #ffffff;
  border-radius: 30px;

  position: absolute;
  bottom: 0;

  min-height: 5rem;
  max-height: 35rem;
  min-width: 10rem;
  width: inherit;

  display: flex;
  justify-content: flex-start;

  z-index: 99999;
}

.my_speed_dial__open_button {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background-color: #1480ec;
  display: flex;
  align-items: center;
  justify-content: center;
}

.my_speed_dial__icon_color{
  filter: brightness(0) saturate(100%) invert(100%) sepia(5%) saturate(0%) hue-rotate(310deg) brightness(102%) contrast(102%);
}

.my_speed_dial__children_wrapper {
  margin: 1rem 1rem 5rem;
  width: inherit;
}

.my_speed_dial__row {
  display: flex;
  margin: 1rem;
}

@-webkit-keyframes scale-up-ver-bottom {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scale-up-ver-bottom {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}