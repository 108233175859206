.container {
  width: 55rem;
  height: max-content;
  padding: 1rem;
  margin: 0 auto;
  box-shadow: var(--box-shadow);

  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.full_width {
  width: 100%;
  border: none!important;
}

.close_icon {
  transform: rotate(180deg);
}

.cell_none_border {
  border: none!important;
}

.row {
  border: none;
  height: 2.5rem;
  display: flex!important;
  justify-content: space-between;
  align-items: center;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-18 15:59:29
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
