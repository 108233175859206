.photo_with_zoom__photo {
  cursor: pointer;
}

.figure {
  height: 81vh;
  background-repeat: no-repeat;

  & img {
    display: block;
    pointer-events: none;
    width: 100%;
    height: 100%;
  }
}

.figure:hover {
  & img {
    opacity: 0;
  }
}

.photo_with_zoom__zoom_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}