.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 76rem;

  .select {
    width: 18rem;
    margin-left: 1rem;
  }

  & button {
    margin-left: 1rem;
    height: 2.5rem;
  }

  & > * {
    margin: 1rem;
  }
}