.search_diff_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  height: 100%;

  .search_diff__pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 12rem;
    margin-right: 1rem;

    .__pagination {
      width: auto;
    }

    & > * {
      margin: 1rem;
    }
  }

  .search_diff__table {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90%;
    overflow: auto;
  }
}

.search_diff__accordion__elem {
  display: flex;

  & li {
    margin-right: 2rem;
  }

  & a {
    margin-right: 2rem;
  }
}

.search_diff__accordion__body {
  display: flex;
  align-content: center;
  justify-content: center;
}

.search_diff__accordion__body__photos {
  width: 40%;
}