.container {
  margin-left: 10rem;
  margin-top: 1rem;
  width: auto;
  border-radius: 10px;
  display: flex;
  align-items: center;
  background: #fafafa;

  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

  .edit_profile_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1rem;

    & > * {
      margin: 1rem;
    }
  }

  .data_container {
    text-align: right;
    margin-left: 3rem;
    margin-right: 1rem;
  }

  .label_wrapper {
    margin-left: 1rem;
  }
}

.wrapper {
  margin-top: 5rem;
  display: flex;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
