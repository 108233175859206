.wrapper {
  width: auto;
  padding: 0 .5rem;
  display: flex;

  .label {

  }

  .value {
    margin: 0 .5rem;
    font-weight: bold;
  }
}