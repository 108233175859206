.container {
  padding-top: 5rem;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.box {
  padding: 1.5rem 3rem;
  box-shadow: 4px 4px 8px 6px rgba(34, 60, 80, 0.2);
  border-radius: var(--border-radius);
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .link_wrapper {
    display: flex;
    justify-content: center;
    align-content: center;

    .link{
      color: #1480ec;
      text-decoration: none;
    }
  }

  .field {
    margin-top: .8rem;
  }

  .load__btn {
    margin-top: .8rem;
  }
}