.info__button {
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info__button:hover {
  cursor: pointer;
  background: #e7e7e7;
}