.wrapper {
  box-shadow: var(--box-shadow);
  width: 47vw;

  .main__photo__wrapper {
    height: 62vh;

    .big__photo {
      display: flex;
      justify-content: flex-end;
      height: 100%;

      .rotate_icon {
        transform: rotate(-90deg);
        cursor: pointer;
      }

      .mirror_icon {
        transform: rotateY(180deg) rotate(-90deg);
        cursor: pointer;
      }

      .figure {
        height: 90%;
      }

      .figure:hover img {
        opacity: 0;
      }

      & img {
        object-fit: contain;
        height: 100%;
      }
    }
  }

  .preview__photos {
    display: flex;
    align-items: center;
    height: 7vh;

    .active_photo {
      border-radius: .4rem;
      border: #00e300 solid .3rem;
    }

    & img {
      object-fit: contain;
      margin-left: .5rem;
      height: 100%;
      width: auto;
    }
  }
}