.caption {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 5rem repeat(7, 10rem);

  & b {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem 0;
    border-bottom: 1px solid black;
  }
}

.custom_scroll {
  &::-webkit-scrollbar {
    width: 4px;
    &-thumb {
      background-color: #1976d2;
      width: 1px;
      border-radius: 16px;
    }
  }
}

.modal_wrapper {
  margin-top: 1rem;
  width: 100%;
  height: 100%;
}

.grave_info_wrapper {
  box-shadow: var(--box-shadow);
  padding: .5rem 1rem;
  overflow: auto;
  width: 55%;
  height: calc(100vh - 15rem);
  //-webkit-animation: slide-in-fwd-left 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  //animation: slide-in-fwd-left 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

  &::-webkit-scrollbar {
    width: 3px;
    &-thumb {
      background-color: #1976d2;
      width: 1px;
      border-radius: 16px;
    }
  }

  & div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & b {
      max-width: 18rem;
    }
  }
}

.grave_img_wrapper {
  display: flex;
  justify-content: center;
  overflow: auto;
  padding-right: 1rem;
  //-webkit-animation: slide-in-fwd-right 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  //animation: slide-in-fwd-right 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

  &::-webkit-scrollbar {
    width: 3px;
    &-thumb {
      background-color: #1976d2;
      width: 1px;
      border-radius: 16px;
    }
  }

  & div {
    position: relative;

    .remove_photo {
      position: absolute;
      top: 0;
      right: 0;
      color: #f3033a;

      &:hover {
        cursor: pointer;
      }
    }

    & img {
      width: 100%;
      max-height: calc(80vh - 100px);
    }
  }
}

.page_icon {
  font-size: 3rem;
  color: #1480ec;

  &:hover {
    cursor: pointer;
    color: #0e5cab;
  }
}

.last_page_icon {
}

.first_page_icon {
  transform: rotate(180deg);
}

.graves__container {
  //-webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  //animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-18 15:59:29
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-18 16:34:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-fwd-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-right {
  0% {
    -webkit-transform: translateZ(-1400px) translateX(1000px);
    transform: translateZ(-1400px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
    transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-right {
  0% {
    -webkit-transform: translateZ(-1400px) translateX(1000px);
    transform: translateZ(-1400px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
    transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-18 16:33:21
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-fwd-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-left {
  0% {
    -webkit-transform: translateZ(-1400px) translateX(-1000px);
    transform: translateZ(-1400px) translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
    transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-left {
  0% {
    -webkit-transform: translateZ(-1400px) translateX(-1000px);
    transform: translateZ(-1400px) translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
    transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}