.wrapper {
  height: 80vh;

  .table_wrapper {
    height: 75vh;
  }
}

.user_permission_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50rem;

  .user_permissions_wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    border: #1480ec solid 2px;
    border-radius: 10px;
    width: 100%;
    min-height: 3rem;

    & div {
      margin: 0.5rem;
    }
  }

  .user_permissions__input {
    width: 50rem;
    height: 10rem;
  }
}

.role__tooltip {
  font-size: 1rem;
  width: 50rem;
}

.info_wrapper {
  display: flex;
  flex-direction: column;

  .info__item {
    display: flex;
    margin: 0.2rem;
  }
}

.muni_dialog_content {
  display: flex;
  justify-content: center;
  align-items: center;

  .muni_dialog__select {
    margin: 0.5rem;
    width: 20rem;
  }
}

.register_user_btn {
  padding: 1rem;
}

.box_sign_up {
  padding: 1.5rem 3rem;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .field {
    margin-top: .8rem;
  }

  .load__btn {
    margin-top: .8rem;
  }
}