.enabled_btn {
  border: 3px solid #ffffff;
}

.save_after_moving_btn {
  margin-top: 21rem;
}

.info_btn {
  margin-top: 24rem;
}

.add_btn {
  margin-left: 5rem;
  margin-top: 5rem;
}

.edit_geometry_mode_btn {
  margin-left: 8rem;
  margin-top: 5rem;
}

.info_btn_animation {
  -webkit-animation: color-change-2x 1.5s linear 2 alternate both;
  animation: color-change-2x 1.5s linear 2 alternate both;
}

.info_icons_wrapper {
  display: flex;
}

.rotate_left_btn {
  margin-left: 8rem;
}

.rotate_right_btn {
  margin-left: 5rem;
}

.move_left_btn_container {
  margin-top: 40vh;
}

.move_top_btn_container {
  margin-left: 47vw;
}

.move_right_btn_container {
  position: absolute;
  left: 96%;
  top: 40vh;
}

.move_bottom_btn_container {
  margin-left: 47vw;
  margin-top: 77vh;
}

.arrow_top {
  transform: rotate(90deg);
}

.arrow_right {
  transform: rotate(180deg);
}

.arrow_bottom {
  transform: rotate(270deg);
}

.mirroring {
  transform: rotateY(180deg);
}

.move_buttons {
  width: 4rem;
  height: 2.3rem;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    background: #f1f1f1;
  }
}

.custom_buttons_container {
  margin-top: 15rem;
  display: flex;
  flex-direction: column;
}

.wai_container {
  margin-top: 15rem;
}

.ruler_container {
  margin-top: 18rem;
}

.ruler_container {
  margin-top: 18rem;
}

.select_finger_container {
  margin-top: 21rem;
}

.select_finger_ruler_is_on {
  margin-top: 35rem;
}

.markers_info__container {
  display: flex;
  flex-direction: column;
  border-top: #dcdcdc 1px solid;
}

.markers_info__wrapper {
  display: flex;
  align-items: center;

  & img {
    margin-left: 1rem;
    height: 1.5rem;
    background-size: cover;
  }
}

.ruler__coordinates_wrapper {
  display: flex;
}

.ruler__coordinates__copy_btn {
  margin-left: 1rem;
  cursor: pointer;
}

.ruler__popup_coordinates__wrapper {
  display: flex;
  align-items: center;
  width: 13rem;
}

.custom_ruler_is_on_btn {
  min-width: 15rem;
  min-height: 10rem;
  background: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  -webkit-animation: scale-up-tl 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-tl 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

  .off_ruler_btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .ruler_info {
    margin-left: 1rem;
  }

  &:hover {
    cursor: pointer;
    background: #f1f1f1;
  }
}

.set_row_is_on_btn {
  min-width: 15rem;
  min-height: 15rem;
  background: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  -webkit-animation: scale-up-tl 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-tl 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

  .off_set_row_btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .set_row_body {
    display: flex;
    flex-direction: column;
    margin: 1rem;

    & > * {
      margin: 0.5rem 0;
    }
  }

  &:hover {
    cursor: pointer;
    background: #f1f1f1;
  }
}

.custom_btn {
  width: 2.4rem;
  height: 2.3rem;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    background: #f1f1f1;
  }
}

.rotate_out_center {
  -webkit-animation: rotate-out-center 0.8s cubic-bezier(0.550, 0.085, 0.680, 0.530) reverse both;
  animation: rotate-out-center 0.8s cubic-bezier(0.550, 0.085, 0.680, 0.530) reverse both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-20 11:19:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-out-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
    opacity: 0;
  }
}

@keyframes rotate-out-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
    opacity: 0;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-20 16:31:43
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-20 16:33:55
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation color-change-2x
 * ----------------------------------------
 */
@-webkit-keyframes color-change-2x {
  0% {
    background: #fdfdfd;
  }
  50% {
    width: 3rem;
    height: 3rem;
    background: #42ff00;
  }
  100% {
    background: #fdfdfd;
  }
}

@keyframes color-change-2x {
  0% {
    background: #fdfdfd;
  }
  50% {
    width: 3rem;
    height: 3rem;
    background: #42ff00;
  }
  100% {
    background: #fdfdfd;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-9-8 11:36:37
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-tl
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-tl {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}

@keyframes scale-up-tl {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}


