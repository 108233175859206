.wrapper {
  position: relative;
  height: 25rem;
  width: 25rem;
  margin-left: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.doughnut {
  position: absolute;
  width: 25rem;
  height: 25rem;
  z-index: 2;
}

.center {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #1480ec;
  border-radius: 50%;
  width: 13rem;
  height: 13rem;
}

.legend {
  margin: 2rem;
  width: 40rem;

  .colorContainer {
    width: 4rem;
    height: 1.5rem;
    margin-right: 2rem;
  }

  .accordion-content {
    display: flex;

    .accordion-doughnut {
      width: 5rem;
      height: 5rem;
    }
  }
}




