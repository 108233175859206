.inputs__stack {
  flex-grow: 1;
  width: 60rem;
  overflow: auto;

  .field {
    padding: .8rem .9rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #b4b4b4;
  }

  .sources {
    display: flex;
    height: 10rem;
    width: 100%;
    margin: 1rem 0;
    justify-content: space-between;
  }

  .select {
    min-height: 3rem;
    width: 35rem;
    margin: 0 1rem;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}