.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 60rem;
  font-size: 1.2rem;
  text-align: justify;
}

.text_with_margin {
  margin-bottom: 1rem;
}

.center_text {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
}

.container {
  display: flex;
  justify-content: center;
}