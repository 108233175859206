@function calcFluidFontSize($f-min, $f-max, $v-min, $v-max, $units: px) {
  $k: ($f-max - $f-min)/($v-max - $v-min);
  $b: $f-min - $k * $v-min;

  $b: $b + $units;

  @return calc(#{$k} * 100vw + #{$b});
}

html {
  font-size: calcFluidFontSize(.7, 5, 60, 2560, rem);
}

td {
  text-align: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

:root {
  --border-radius: 10px;
  --box-shadow: 1px 1px 5px 1px rgba(34, 60, 80, 0.1);
}
