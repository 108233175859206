.modal_select_finger {
  overflow: auto;
  position: absolute;
  z-index: 2000;
  bottom: 4rem;
  right: 1rem;
  width: 40rem;
  height: 27rem;
  max-height: 27em;
  background-color: white;
  border-radius: .5rem;

  &::-webkit-scrollbar {
    width: 3px;

    &-thumb {
      background-color: #1976d2;
      width: 1px;
      border-radius: 16px;
    }
  }

  .icon_wrapper {
    display: flex;
    justify-content: space-between;
    margin: 1rem;

    & span {
      font-size: 1rem;
      padding: .5rem;
    }

    .close {
      cursor: pointer;
    }
  }

  .project_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;

    & h2 {
      margin: 0;
    }

    & span {
      display: inline-block;
      margin-bottom: .5rem;
      font-size: 1.1rem;
      font-weight: lighter;
      font-style: italic;
    }

    .current_project {
      text-align: center;
    }

    .recipient_project {
      text-align: center;
    }
  }

  .select_finger_content_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: .5rem;
    margin-bottom: 1rem;
    text-align: center;

    .name_places {
      font-size: 1.5rem;
      font-style: italic;
    }

    .name_sections {
      font-size: 1.5rem;
      font-style: italic;
    }

    .select_finger_content {
      font-size: 1.3rem;
      padding-top: .5rem;
    }
  }

  .select_finger_checkbox {
    & span {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.modal_task_or_note {
  overflow: auto;
  position: absolute;
  z-index: 2000;
  bottom: 4rem;
  right: 1rem;
  width: 45rem;
  height: 27rem;
  max-height: 27em;
  background-color: white;
  border-radius: .5rem;

  &::-webkit-scrollbar {
    width: 3px;

    &-thumb {
      background-color: #1976d2;
      width: 1px;
      border-radius: 16px;
    }
  }

  .close {
    position: absolute;
    right: .5rem;
    top: .5rem;
    cursor: pointer;
  }

  .delete {
    cursor: pointer;
    margin-right: 2rem;
  }

  .edit {
    cursor: pointer;
    margin-left: 2rem;
  }

  .task_and_note_icon_wrapper {
    display: flex;
    margin-top: 3rem;
    justify-content: space-between;

    & span {
      font-weight: bold;
      font-size: 1.5rem;
    }
  }

  .input_change_description {
    margin-top: .5rem;
    margin-bottom: .5rem;
    margin-left: 1.5rem;
    width: 42rem;
  }

  .save_description {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & span {
      margin-left: .5rem;
    }
  }

  .task_and_note_description {
    text-align: justify;
    font-size: 1.2rem;
    padding: 1rem;
    margin: 0;
  }

  .switch {
    text-align: center;
    margin-top: 1rem;
  }
}

// End task or note modal styles

.modal {
  overflow: auto;
  position: absolute;
  z-index: 2000;
  right: 1rem;
  width: 25rem;
  //height: 30rem;
  background-color: white;
  border-radius: .5rem;

  &::-webkit-scrollbar {
    width: 3px;

    &-thumb {
      background-color: #1976d2;
      width: 1px;
      border-radius: 16px;
    }
  }

  .copy_btn_wrapper {
    cursor: pointer;
  }

  .close {
    position: absolute;
    right: .5rem;
    top: .5rem;
    cursor: pointer;
  }

  .delete {
    cursor: pointer;
    margin-right: 2rem;
  }

  .edit {
    cursor: pointer;
    margin-left: 2rem;
  }

  & p {
    text-align: center;
    font-size: 1.1rem;
  }

  & a {
    padding: .2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;

    & span {
      margin-top: .3rem;
      margin-left: .2rem;
      text-align: left;
      width: max-content;
    }
  }
}

.section_and_border_modal_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;

  .section_and_border_modal_delete_btn {
    margin: 0 2rem;
  }
}

.sections_numbers__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sections_numbers__content {
  max-height: 30rem;
  min-width: 10rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.sections_numbers__item {
  min-width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  justify-content: center;
  border: 2px dotted rgba(255, 145, 0, 0);
  border-radius: 20px;
  font-size: 1.3rem;
  cursor: pointer;
}

.sections_numbers__selected_item {
  min-width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  justify-content: center;
  border: 2px solid rgb(101, 255, 0);
  border-radius: 20px;
  font-size: 1.3rem;
  cursor: pointer;
}

.sections_numbers__item:hover {
  border-color: #ff9100;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes color-change-2x {
  0% {
    background: #fdfdfd;
  }
  50% {
    width: 3rem;
    height: 3rem;
    background: #42ff00;
  }
  100% {
    background: #fdfdfd;
  }
}

@keyframes color-change-2x {
  0% {
    background: #fdfdfd;
  }
  50% {
    width: 3rem;
    height: 3rem;
    background: #42ff00;
  }
  100% {
    background: #fdfdfd;
  }
}


