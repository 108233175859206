.nv__wrapper {
  min-width: 8vw;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: start;

  .link {
    width: 80%;
    text-align: start;
    font-size: 1.2rem;
    text-decoration: none;
    margin-bottom: 1rem;
    padding: 1rem;
    color: #1480ec;
    background: #f6f6f6;
    transition: all 0.3s;
    border-radius: 0.5rem;
  }

  .link:hover {
    color: #ffffff;
    background: #72acee;
  }

  .active__link {
    background: #1480ec;
    color: #e8e8e8;
  }

  .active__link:hover {
    background: #1480ec;
    color: #e8e8e8;
  }
}

@media (max-width: 480px) {
  .nv__wrapper {
    min-width: 12vw;
    margin-right: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    .link {
      width: 100%;
      text-align: start;
      font-size: 1.2rem;
      text-decoration: none;
      padding: 1rem;
      color: #1480ec;
      background: #f6f6f6;
      transition: all 0.3s;
    }

    .link:hover {
      color: #ffffff;
      background: #72acee;
    }

    .active__link {
      background: #1480ec;
      color: #e8e8e8;
    }

    .active__link:hover {
      background: #1480ec;
      color: #e8e8e8;
    }
  }
}