.wrapper {
  box-shadow: var(--box-shadow);
  margin-top: 1vh;
  height: 6vh;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .sort__places {
    width: 15vw;
  }

  .go__to {
    display: flex;
    justify-content: center;
    width: 30vw;

    & span {
      margin-right: 1rem;
    }

    & button {
      margin-left: 2rem;
    }
  }

  .buttons {
    width: 23vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .count {
    display: flex;
    justify-content: center;
    width: 23vw;
  }
}