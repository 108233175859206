.cartHeader_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

 & p {
   margin-right: 2rem;
   font-size: 1.5rem;
   margin-left: 1rem;
 }
}