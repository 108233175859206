.wrapper {
  width: 23vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.grave__info {
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.form {
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;

  & button {
    width: 20rem;
    margin-top: 1rem;
  }

  .select {
    margin-top: 1rem;
    width: 20rem;
  }
}