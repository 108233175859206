.wrapper {
  width: 100vw;
  height: calc(100vh - 5rem);
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 5px;
    &-thumb {
      background-color: #1976d2;
      width: 1px;
      border-radius: 16px;
    }
  }
}

.content {
  margin-top: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  height: calc(100vh - 15rem);
}