.dialog_wrapper {
  display: flex;
  flex-direction: column;

  .search__input {
    margin-top: .7rem;
    width: 25rem;
  }
}

.select__button {
  height: 2.5rem;
}

.projects_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .project__item {
    width: max-content;
  }
}