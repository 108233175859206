.wrapper {
  margin-top: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  height: calc(100vh - 15rem);
  align-items: center;
  display: flex;
  justify-content: center;

  .form {
    display: flex;
    flex-direction: column;

    & > * {
      margin: 0.5rem;
    }
  }
}