.container {
  display: flex;
  justify-content: center;
  flex-direction: column;

  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.card_form {
  width: 28rem;
}

.card_form_duplicate_form {
  width: 100%;
}

.form__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.find_duplicates_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  height: 100%;

  .find_duplicates__pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 12rem;
    margin-right: 1rem;

    .__pagination {
      width: auto;
    }

    & > * {
      margin: 1rem;
    }
  }

  .find_duplicates__table {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90%;
    overflow: auto;
  }

  .find_duplicates__accordion__table__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .find_duplicates__accordion__table {
    width: 100%;
  }
}

.find_duplicates__accordion__elem {
  display: flex;

  & li {
    margin-right: 2rem;
  }

  & a {
    margin-right: 2rem;
  }
}

.find_duplicates__accordion__body {
  display: flex;
  align-content: center;
  justify-content: center;
}

.find_duplicates__accordion__body__photos {
  width: 40%;
}

.rotate_arrow {
  transform: rotateY(180deg);
}



@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}