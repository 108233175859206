.wrapper {
  width: 23vw;

  .place__photos__wrapper {
    box-shadow: var(--box-shadow);
    min-height: 20rem;
    max-height: 23rem;

    .place__photos {
      display: flex;
      overflow: auto;

      &::-webkit-scrollbar {
        width: .5rem;
        height: .4rem;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 1rem;
        background: #1976d2;
      }

      & p {
        text-align: center;
        margin: .5rem;
        width: 20%;
        border: #f3033a solid 1px;
        height: 5rem;
      }

      .place_image {
        & img {
          box-shadow: var(--box-shadow);
          margin: .5rem;
          object-fit: contain;
          height: 14rem;
          min-width: 10rem;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .icons_wrapper {
    display: flex;
    justify-content: space-between;
    height: 2rem;

    .rotate_icon {
      transform: rotate(-90deg);
      cursor: pointer;

      & img {
        width: 2rem;
        height: 2rem !important;
        background-position: center;
        background-size: contain;
        box-shadow: none !important;
      }
    }

    .mirror_icon {
      transform: rotateY(180deg) rotate(-90deg);
      cursor: pointer;

      & img {
        width: 2rem;
        height: 2rem !important;
        background-position: center;
        background-size: contain;
        box-shadow: none !important;
        min-width: 2rem !important;
      }
    }
  }

  .graves__with__title {
    margin-top: 1rem;
    box-shadow: var(--box-shadow);
    height: 63%;

    .graves__wrapper {
      overflow: auto;
      display: flex;
      flex-wrap: wrap;

      &::-webkit-scrollbar {
        width: .5rem;
        height: .4rem;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 1rem;
        background: #1976d2;
      }

      .grave__wrapper {
        width: 23%;

        & p {
          box-shadow: var(--box-shadow);
          text-align: center;
          margin: .5rem;
          width: 20%;
          height: 7rem;
        }

        & img {
          box-shadow: var(--box-shadow);
          margin: .5rem;
          object-fit: contain;
          width: 85%;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.active_photo {
  min-width: 5rem;
  border-radius: .5rem;
  border: #1480ec solid .3rem;
}

.is__processed__photo {
  min-width: 5rem;
  border-radius: .5rem;
  border: #00e300 solid .3rem;
}

.is__not__processed__photo {
  min-width: 5rem;
  border-radius: .5rem;
  border: #fc1000 solid .3rem;
}

.title {
  text-align: center;
  font-size: 1.2rem !important;
}