.card {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  float: right;
  width: 100%;
}

.slider__wrapper {
  display: flex;
  justify-content: space-around;

  & p {
    margin-right: 1rem;
  }
}

.slider {
  margin: 0 1rem;
  width: 25rem!important;
}

.mirror_icon {
  transform: rotateY(180deg) rotate(-90deg);
  cursor: pointer;

  & img {
    width: 2rem;
    height: 2rem !important;
    background-position: center;
    background-size: contain;
    box-shadow: none !important;
    min-width: 2rem !important;
  }
}

.icon {
  transform: rotate(-90deg);
  cursor: pointer;

  & img {
    width: 2rem;
    height: 2rem !important;
    background-position: center;
    background-size: contain;
    box-shadow: none !important;
    min-width: 2rem !important;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-18 15:59:29
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}