.container {
  display: flex;
  justify-content: center;
  flex-direction: column;

  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

  & > * {
    margin: 0.5rem;
  }

  .data_card {

    & li {
      padding: 1rem;
    }

    & p {
      padding-left: 1rem;
    }

  }
}

.overflow {
  overflow-y: auto;
  height: 5rem;

  &::-webkit-scrollbar {
    width: 3px;
    &-thumb {
      background-color: #1976d2;
      width: 1px;
      border-radius: 16px;
    }
  }

}
.dialog_container {
  margin-top: 5rem;
}

.table_header {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
}

.table {
  min-width: 30rem;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}