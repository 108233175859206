.wrapper {
  display: flex;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > * {
    margin-left: 1rem;
  }
}

.history__wrapper {
  overflow: auto;
  max-height: 70vh;
}

.mistake_field {
  color: red;
}

.field {
  color: black;
}

.link {
  text-decoration: none;
  color: #1480ec;
  padding: .5rem;

  &:hover {
    background: #ececec;
  }
}