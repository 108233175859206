.wrapper {
  height: 72vh;

  .content {
    height: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.empty__place {
  width: 67vh;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}