.wrapper {

  --width: 40rem;

  .listing {
    box-shadow: var(--box-shadow);
    min-height: 70vh;
    max-height: 70vh;
    overflow: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .statistic__listing {
    width: var(--width);
  }

  .sync__listing {
    width: 30rem;
  }

  .statistic__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: var(--width);
  }

  .title__wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: sticky;
    top: 0;
    background: #fff;

    & p {
      text-align: center;
    }
  }
}

