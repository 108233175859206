.loader_wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  .loader {
    margin: auto;
  }
}

.wrapper {
  height: 80vh;

  .title {
    display: flex;
    align-items: center;

    & button {
      margin-left: 3rem;
    }
  }

  .table_wrapper {
    height: 70vh;
  }
}