
.wrapper {
  position: relative;
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

  .hint {
    padding: .5rem;
    background-color: rgb(200, 0, 255);
    border-radius: .2rem;
  }

  .leaflet_container {
    width: 100%;
    height: calc(100vh - 14rem);
  }
}

.info_btn_animation {
  width: 3rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-animation: color-change-2x 1.5s linear 2 alternate both;
  animation: color-change-2x 1.5s linear 2 alternate both;
  border-radius: 10px;
}

.white_icon_color {
  filter: brightness(0) saturate(100%) invert(100%) sepia(5%) saturate(0%) hue-rotate(310deg) brightness(102%) contrast(102%);
}

.info_wrapper {
  font-size: 1.2rem;
  color: black;
}

.warning_text {
  color: #ff0000;
  font-size: 1.4rem;
}

.add_icon {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background-color: #1480ec;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.info_btn_animation:hover {
  cursor: pointer;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-18 15:59:29
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes color-change-2x {
  0% {
    background: #fdfdfd;
  }
  50% {
    width: 3rem;
    height: 3rem;
    background: #42ff00;
  }
  100% {
    background: #fdfdfd;
  }
}

@keyframes color-change-2x {
  0% {
    background: #fdfdfd;
  }
  50% {
    width: 3rem;
    height: 3rem;
    background: #42ff00;
  }
  100% {
    background: #fdfdfd;
  }
}

