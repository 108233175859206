.wrapper {
  height: 6vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.statistic__wrapper {
  width: 75vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 1.2rem;

  .field {
    display: flex;
    align-items: center;

    .label {

    }

    .value {
      margin: 0 .5rem;
      font-weight: bold;
    }
  }
}